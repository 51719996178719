@import 'components/Base/Fonts//Fonts.scss';

.divider {
  margin: 4px 0;
  border-bottom: 1px solid var(--grey-color-lighter);
}

.header {
  color: var(--grey-color);
  padding: 5px 8px;
  font-size: $m;
  font-weight: 600;
}
