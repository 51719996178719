.dialog-body {
  display: grid;
  gap: 16px;
  margin: 16px 24px 8px;

  &_without-content {
    margin: 0;
  }
}

.actions {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  gap: 8px;

  &_without-content {
    justify-content: right;
  }

  &__delete-btn {
    align-items: center;
    padding-right: 12px;
    padding-left: 12px;
  }
  &__delete-icon {
    margin-right: 8px;
    margin-bottom: -2px;
  }
}
