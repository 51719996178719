.body {
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 4px;
  margin-top: 4px;
  border-radius: 10px;
  background: var(--white-color);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1060;

  &_size_m {
    padding: 8px;
  }

  &_textAlignCenter {
    .option {
      text-align: center;
    }
  }
}

.option {
  padding: 4px 8px;
  white-space: nowrap;
  transition-duration: 0.2s;
  border-radius: 5px;
  box-sizing: content-box;
  background-color: transparent;
  border: none;
  display: block;
  text-align: left;
  cursor: pointer;

  &:hover {
    background: var(--grey-color-lightest);
    cursor: pointer;
  }

  &_size_m {
    font-size: 14px;
  }

  &_size_s {
    font-size: 12px;
  }
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1060;
}
