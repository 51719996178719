@import 'components/Base/Fonts/Fonts';

.container {
  position: relative;
  width: 100%;
}

.textarea {
  display: block;
  width: 100%;
  font-size: $l;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid var(--grey-color-light);
  outline: none;
  transition: border-color 0.3s, color 0.3s, box-shadow 0.3s, background-color 0.3s;
  box-sizing: border-box;
  color: var(--black-color);
  resize: vertical;
  min-height: 40px;
  height: 134px;
  line-height: 22px;

  &:focus {
    box-shadow: 0 0 6px var(--primary-background-color);
    border-color: var(--primary-color);
  }

  &.textarea_invalid {
    box-shadow: 0 0 2px var(--danger-color);
    border-color: var(--danger-color);
  }

  &:disabled {
    background: var(--grey-color-lightest);
    color: var(--grey-color);
    cursor: not-allowed;
  }

  &::placeholder {
    color: var(--grey-color-darker);
    font-weight: 300;
  }
}
