.container {
  position: relative;
  display: inline-flex;
  justify-content: space-around;
  width: 15px;
  height: 15px;
  text-align: center;
  background: var(--grey-color-lighter);
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:hover {
    background: var(--grey-color-light);
  }

  .mark {
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    color: var(--white-color);
    transition: all 0.1s ease-in-out;
  }
}
