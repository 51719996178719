@keyframes spinner-rotate {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.container {
  display: inline-block;
  position: relative;
  animation: spinner-rotate 1s linear infinite;

  &_size_m {
    width: 16px;
    height: 16px;
  }

  &_size_s {
    width: 12px;
    height: 12px;
  }
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid var(--primary-color);
  border-right-color: transparent;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
