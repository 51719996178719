.container {
  padding: 12px 16px;

  &_is_blank {
    border-radius: 0;
    border: none;
  }

  &_is_outlined {
    border-radius: 4px;
    border: 1px solid var(--grey-color-lightest);
  }
}
