.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px 7px 4px;
  border-radius: 3px;
  background: #a0cd64;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 10px;
  font-weight: 400;
  color: var(--white-color);

  &_size_small {
    padding: 4px 4px 2px 5px;
    font-size: 9px;
  }

  &_size_extra-small {
    font-size: 7px;
    padding: 0 4px;
    height: 14px;
  }

  &_appearance_primary {
    background-color: var(--primary-color);
  }

  &_appearance_warning {
    background-color: var(--warning-color);
  }
}
