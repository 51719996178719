.container {
  position: relative;
  overflow: hidden;
  height: 0;

  &_expanded {
    height: auto;
  }
}

.toggler {
  margin-right: 8px;
  cursor: pointer;
  font-size: 10px;
  transition: transform 0.1s linear;
  transform: rotate(-90deg);

  &_expanded {
    transform: rotate(0);
  }
}
