@import 'components/Base/Fonts/Fonts';

.container {
  max-width: 600px;
  max-height: 90vh;
  min-width: 300px;
  background: var(--white-color);
  border-radius: 10px;
  filter: drop-shadow(0 5px 15px rgba(0, 0, 0, 0.25));
  display: flex;
  flex-direction: column;
  word-break: break-word;
  line-height: 1.2;
}

.title {
  font-weight: bold;
  font-size: $l;
  color: var(--black-color);
  padding: 16px 24px 8px 24px;
}

.content {
  font-size: $m;
  font-weight: 300;
  color: var(--grey-color-darker);
  padding: 8px 24px 16px 24px;
  flex: 1 1 auto;
}

.actions {
  padding: 16px 24px;
  font-size: $m;
}
