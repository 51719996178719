$side-menu-width: 74px;
$side-width: 320px;
$window-width: 1250px;
$scrollbar-width: 17px;
$hubspot-width: 100px;
$form-width: 820px;

.container {
  background: var(--white-color);
  display: flex;
  min-width: $window-width - $side-menu-width - $scrollbar-width;
  min-height: 100%;

  &_full {
    display: block;

    &.container_form {
      min-width: 0;
    }
  }
}

.title {
  padding: 24px 24px 40px;
}

.side {
  position: sticky;
  width: $side-width;
  z-index: 1;
  top: 0;

  &-content {
    padding: 0 24px 48px;
  }

  &-title {
    padding: 24px;
  }

  &_disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.main {
  width: calc(100vw - #{$side-menu-width} - #{$side-width} - #{$scrollbar-width});
  min-width: $window-width - $side-width - $side-menu-width - $scrollbar-width;
  padding: 24px $hubspot-width 48px 0;

  &_form {
    padding: 0 $hubspot-width 0 0;
  }

  &_full {
    width: calc(100vw - #{$side-menu-width} - #{$scrollbar-width});
    min-width: $window-width - $side-menu-width - $scrollbar-width;
    padding: 0 $hubspot-width 48px 24px;

    &.main_form {
      padding: 0 $hubspot-width;
      margin: 0 auto;
      min-width: 0;
      width: $form-width + $hubspot-width + $hubspot-width;
    }
  }
}
