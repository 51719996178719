@mixin linear-gradient-left-extended($values...) {
  background-image: -webkit-linear-gradient(left, $values);
  background-image: -moz-linear-gradient(left, $values);
  background-image: -ms-linear-gradient(left, $values);
  background-image: linear-gradient(to right, $values);
}

@keyframes spinner-rotate {
   0% {
       transform: rotate(0deg);
   }
   100% {
       transform: rotate(360deg);
   }
}

.container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--grey-color-lightest);
    z-index: 3;

    &_hidden {
      display: none;
    }
}

.spinner {
    @include linear-gradient-left-extended(rgba(0,0,0,1) 0%, rgba(0,0,0,1) 50%, rgba(0,0,0,.4) 50.1%, rgba(0,0,0,.4) 100%);

    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    width: 34px;
    height: 34px;
    margin: -17px 0 0 -17px;
    border-radius: 100%;
    opacity: .25;
    animation: spinner-rotate 1s linear infinite;

    &:after {
        content: "";
        display: block;
        position: relative;
        width: 22px;
        height: 22px;
        top: 6px;
        left: 6px;
        border-radius: 100%;
        background: var(--grey-color-lightest);
    }
}
