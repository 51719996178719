.dropdown-container {
  width: 35px;

  input {
    color: var(--primary-color);
    font-size: 16px;
    text-align: center;
    height: 24px;

    &:not([readonly]) {
      cursor: pointer;
    }
    &:read-only {
      color: var(--grey-color-light);
    }
  }
}

.body {
  max-height: 202px;
  overflow: auto;

  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 0px;
  }
}

.interval {
  margin-left: 0.5em;
}
