.actions {
  display: flex;
  justify-content: flex-end;

  &_wide {
    justify-content: space-between;
  }

  .ok {
    margin-left: 8px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
