.container {
  display: inline-block;
  position: relative;
  width: 1em;
  height: 1em;
  cursor: pointer;

  &:hover {
    .icon_checked {
      color: var(--primary-color-dark);
    }

    .icon_unchecked {
      color: var(--grey-color-light);
    }
  }

  &:active {
    .icon_checked {
      color: var(--primary-color-dark);
    }
    .icon_unchecked {
      color: var(--grey-color);
    }
  }
}

.icon_checked {
  color: var(--primary-color);
  display: none;
}

.icon_unchecked {
  color: var(--grey-color-light);

  .blank {
    color: var(--white-color);
  }
}

input[type='radio'].input {
  position: absolute;
  visibility: hidden;
  top: 0;
  left: 0;
  margin: 0;
  width: 1em;
  height: 1em;

  &:checked ~ .icon_checked {
    display: block;
  }

  &:checked ~ .icon_unchecked {
    display: none;
  }
}
