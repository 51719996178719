.chip {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  width: max-content;
  color: var(--grey-color-darker);
  background-color: var(--grey-color-lightest);
  border-radius: 6px;
  padding: 4px 8px;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

  &_checked {
    color: var(--primary-color);
    background-color: rgba(var(--primary-color--rgb), 0.15);
  }

  &_excluded {
    color: var(--danger-color);
    background-color: rgba(var(--danger-color--rgb), 0.15);
  }

  & label {
    padding-left: 0;
  }
}

.actions {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  flex-shrink: 0;
  gap: 4px;
  margin-left: 4px;

  .name_isCollocation & {
    margin-left: 0;
  }

  .name_focused:hover & {
    display: inline-flex;
  }

  &__icon {
    font-size: 12px;
    cursor: pointer;

    &_small {
      font-size: 12px;
    }
  }

  &__icon-container {
    line-height: 1;
    font-size: 12px;
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-items: center;
    position: relative;
  }
}
