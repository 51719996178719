@import 'components/Base/Fonts/Fonts';

@function loading-gradient($first-color, $second-color) {
  @return linear-gradient(
    -45deg,
    $second-color 25%,
    $first-color 25%,
    $first-color 50%,
    $second-color 50%,
    $second-color 75%,
    $first-color 75%,
    $first-color
  );
}

@mixin loading($first-color, $second-color) {
  animation: move 1s linear infinite;
  background: loading-gradient($first-color, $second-color);
  background-size: 50px 50px;
}

@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

@mixin filled(
  $base-color,
  $base-color-dark,
  $base-color-darker,
  $base-color-darkest,
  $text-color: var(--white-color)
) {
  background: $base-color;
  border: 1px solid transparent;
  color: $text-color;
  transition: border-color 0.3s, background 0.3s;

  &:hover {
    background: $base-color-dark;
    border-color: $base-color-darker;
  }

  &:active {
    background: $base-color-darker;
    border-color: $base-color-darkest;
  }

  &.button_loading {
    @include loading($base-color, $base-color-darker);
    pointer-events: none;
  }

  &.button_disabled {
    border-color: var(--grey-color);
    background: var(--grey-color-lightest);
    color: var(--grey-color-light);
    cursor: default;
    pointer-events: none;

    &:hover {
      border-color: var(--grey-color);
      background: var(--grey-color-lightest);
    }
  }
}

@mixin outlined(
  $base-color,
  $base-color-border,
  $base-color-border-active,
  $text-color: $base-color
) {
  border: 1px solid $base-color-border;
  color: $text-color;
  transition: border-color 0.3s, background 0.3s;

  &:hover {
    border-color: $base-color;
    background: rgba($base-color, 0.045);
  }
  &:active {
    border-color: $base-color-border-active;
    background: rgba($base-color, 0.08);
  }

  &.button_loading {
    @include loading(rgba($base-color, 0.08), var(--white-color));
    pointer-events: none;
  }

  &.button_disabled {
    border-color: var(--grey-color);
    color: var(--grey-color-light);
    background: transparent;
    cursor: default;
    pointer-events: none;

    &:hover {
      border-color: var(--grey-color);
      background: transparent;
    }
  }
}

@mixin text($base-color, $hover-color) {
  border: 1px solid transparent;
  color: $base-color;
  transition: border-color 0.3s, background 0.3s, color 0.3s;

  &:hover {
    background: rgba($base-color, 0.045);
    color: $hover-color
  }

  &:active {
    background: rgba($base-color, 0.08);
    color: $hover-color
  }

  &.button_loading {
    pointer-events: none;
    &:hover {
      cursor: progress;
    }
  }

  &.button_disabled {
    color: var(--grey-color-light);
    background: transparent;
    cursor: default;
    pointer-events: none;

    &:hover {
      background: transparent;
    }
  }
}

@mixin size($font-size) {
  font-size: $font-size;
  padding: .35em .7em;
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  outline: none;
  border: 1px solid transparent;
  background: none;
  border-radius: 8px;
  color: var(--grey-color-darkest);
  cursor: pointer;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }

  &_color_primary {
    &.button_appearance_filled {
      @include filled(
        var(--primary-color),
        var(--primary-color-dark),
        var(--primary-color-darker),
        var(--primary-color)
      );
    }
    &.button_appearance_outlined {
      @include outlined(var(--primary-color), var(--primary-color), var(--primary-color-darker));
    }
    &.button_appearance_text {
      @include text(var(--primary-color), var(--primary-color-dark));
    }
  }

  &_color_secondary {
    &.button_appearance_filled {
      @include filled(
        var(--secondary-color),
        var(--secondary-color-dark),
        var(--secondary-color-darker),
        var(--secondary-color-darkest)
      );
    }
    &.button_appearance_outlined {
      @include outlined(var(--secondary-color), var(--secondary-color-light), var(--secondary-color-darker));
    }
    &.button_appearance_text {
      @include text(var(--secondary-color), var(--secondary-color-dark));
    }
  }

  &_color_success {
    &.button_appearance_filled {
      @include filled(
        var(--success-color),
        var(--success-color-dark),
        var(--success-color-darker),
        var(--success-color-darker)
      );
    }
    &.button_appearance_outlined {
      @include outlined(var(--success-color), var(--success-color), var(--success-color-darker));
    }
    &.button_appearance_text {
      @include text(var(--success-color), var(--success-color-dark));
    }
  }

  &_color_danger {
    &.button_appearance_filled {
      @include filled(
        var(--danger-color),
        var(--danger-color-dark),
        var(--danger-color-dark),
        var(--danger-color-darker)
      );
    }
    &.button_appearance_outlined {
      @include outlined(var(--danger-color), var(--danger-color), var(--danger-color-dark));
    }
    &.button_appearance_text {
      @include text(var(--danger-color), var(--danger-color-dark));
    }
  }

  &_color_warning {
    &.button_appearance_filled {
      @include filled(
        var(--warning-color),
        var(--warning-color-dark),
        var(--warning-color-darker),
        var(--warning-color-darker)
      );
    }
    &.button_appearance_outlined {
      @include outlined(var(--warning-color), var(--warning-color), var(--warning-color-darker));
    }
    &.button_appearance_text {
      @include text(var(--warning-color), var(--warning-color-dark));
    }
  }
  &_color_orange {
    &.button_appearance_filled {
      @include filled(
        var(--orange-color),
        var(--orange-color-dark),
        var(--orange-color-darker),
        var(--orange-color-darker)
      );
    }
    &.button_appearance_outlined {
      @include outlined(var(--orange-color), var(--orange-color), var(--orange-color-darker));
    }
    &.button_appearance_text {
      @include text(var(--orange-color), var(--orange-color-dark));
    }
  }

  &_color_default {
    &.button_appearance_filled {
      @include filled(
        var(--grey-color-lighter),
        var(--grey-color-light),
        var(--grey-color),
        var(--grey-color-dark),
        var(--grey-color-darkest)
      );
    }
    &.button_appearance_outlined {
      @include outlined(var(--grey-color), var(--grey-color-light), var(--grey-color-darker), var(--grey-color-darkest));
    }
    &.button_appearance_text {
      @include text(var(--grey-color-darkest), var(--grey-color-darkest));
    }
  }

  &_color_grey {
    &.button_appearance_filled {
      @include filled(
        var(--grey-color),
        var(--grey-color),
        var(--grey-color-dark),
        var(--grey-color-dark)
      );
    }
    &.button_appearance_outlined {
      @include outlined(
        var(--grey-color),
        var(--grey-color),
        var(--grey-color-dark),
        var(--grey-color-dark)
      );
    }
    &.button_appearance_text {
      @include text(var(--grey-color-dark), var(--grey-color-darkest));
    }
  }

  &_size_xxs {
    @include size($xxs);
  }
  &_size_xs {
    @include size($xs);
  }
  &_size_s {
    @include size($s);
  }
  &_size_m {
    @include size($m);
  }
  &_size_l {
    @include size($l);
  }
  &_size_xl {
    @include size($xl);
  }
  &_size_xxl {
    @include size($xxl);
  }

  &_block {
    display: flex;
    width: 100%;
  }
}
