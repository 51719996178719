@import 'components/Base/Fonts/Fonts';

.link {
  display: inline-block;
  color: var(--primary-color);
  position: relative;
  line-height: 1;

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0.08em;
    background-color: currentColor;
    transition: 0.15s;
  }

  &:hover {
    &::after {
      transform: scaleX(0);
    }
  }
}
