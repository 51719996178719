.enter {
  opacity: 0;
}

.enterActive {
  opacity: 1;
  transition: all 0.1s ease-in;
}

.exit {
  opacity: 1;
}

.exitActive {
  opacity: 0;
  transition: all 0.1s ease-out;
}

.exitDone {
  opacity: 0;
}
