
.switcher {
  display: inline-block;
  position: relative;
  width: 28px;
  height: 16px;
}

.switcher-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switcher-label {
  display: block;
  width: 100%;
  height: 100%;
  background-color: var(--grey-color-lighter);
  border-radius: 25px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s;

  &:hover {
    background-color: var(--grey-color-light);
  }

  &_checked {
    background-color: var(--primary-color);

    &:hover {
      background-color: var(--primary-color-dark);
    }
  }

  &_disabled, &_disabled:hover {
    background-color: var(--grey-color-lightest);
    cursor: not-allowed;
  }
}

.switcher-inner {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 12px;
  height: 12px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s;
}

.switcher-input:checked + .switcher-inner {
  transform: translateX(12px);
}
