.wrapper {
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid var(--grey-color-lightest);
  padding-bottom: 8px;

  &_horizontalScroll {
    overflow-x: auto;
  }

  &_verticalScroll {
    overflow-y: auto;
  }
}

.container {
  width: 100%;

  thead {
    border-bottom: 1px solid var(--grey-color-lightest);
    font-size: 12px;

    td,
    th {
      padding: 16px;
    }
  }

  tbody {
    font-size: 16px;
    line-height: calc(16px * 1.5);

    td,
    th {
      padding: 12px;
    }
  }

  thead,
  tbody tr:hover {
    background-color: var(--grey-color-lightest);
  }

  td:first-child,
  th:first-child {
    padding-left: 24px;
  }

  td:last-child,
  th:last-child {
    padding-right: 24px;
  }

  th {
    background-color: var(--grey-color-lightest);
  }
}

.icon_order_sort {
  color: var(--grey-color-light);
}
