@import 'components/Base/Fonts/Fonts';

.container {
  position: absolute;
  top: 105%;
  left: 3px;
  font-size: $xs;
  color: var(--danger-color);

  &_static-position {
    position: static;
  }
}

