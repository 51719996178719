@import 'components/Base/Fonts/Fonts';

.attention-label {
  display: inline-block;
  border-radius: 4px;
  font-size: $m;
  font-weight: 500;

  &_color_success {
    background-color: var(--success-color);
    color: var(--white-color);
  }

  &_color_danger {
    background-color: var(--danger-color);
    color: var(--white-color);
  }

  &_color_warning {
    background-color: var(--warning-color);
    color: var(--black-color);
  }

  &_color_orange {
    background-color: var(--orange-color);
    color: var(--white-color);
  }

  &_size_m {
    padding: 8px;
  }

  &_size_l {
    padding: 16px;
  }

  &_wide {
    display: block;
    width: 100%;
  }
}
