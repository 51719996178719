:global {
  .AH-calendar-customization {
    font-family: 'Proxima Nova';

    .rc-calendar-next-month-btn::after,
    .rc-calendar-next-year-btn::after,
    .rc-calendar-prev-month-btn::after,
    .rc-calendar-prev-year-btn::after {
      content: '' !important;
      display: block;
      position: absolute;
      top: 8px;
      width: 16px;
      height: 16px;
      background-repeat: no-repeat;
      filter: grayscale(1);
      transition: 0.2s;
    }

    .rc-calendar-next-year-btn::after,
    .rc-calendar-prev-year-btn::after {
      background-image: url('./images/arrow-double-left.svg');
    }

    .rc-calendar-next-month-btn::after,
    .rc-calendar-prev-month-btn::after {
      background-image: url('./images/arrow-left.svg');
    }

    .rc-calendar-next-month-btn::after,
    .rc-calendar-next-year-btn::after {
      transform: rotate(180deg) translateX(100%);
    }

    .rc-calendar-selected-day .rc-calendar-date[aria-selected='true'] {
      background-color: var(--white-color);
    }

    .rc-calendar-selected-day.rc-calendar-disabled-cell .rc-calendar-date {
      background-color: var(--grey-color-lightest);
    }

    .rc-calendar-date {
      &:hover {
        background-color: rgba(var(--primary-color--rgb), 0.045);
      }
    }

    .rc-calendar-today .rc-calendar-date {
      border-color: var(--primary-color);
    }

    .rc-calendar-selected-date {
      .rc-calendar-date {
        background-color: var(--primary-color);

        &:hover {
          background-color: var(--primary-color);
        }
      }

      &.rc-calendar-selected-day {
        .rc-calendar-date {
          background-color: var(--primary-color);

          &:hover {
            background-color: var(--primary-color);
          }
        }
      }
    }

    .rc-calendar-next-month-btn,
    .rc-calendar-next-year-btn,
    .rc-calendar-prev-month-btn,
    .rc-calendar-prev-year-btn {
      &:hover {
        &::after {
          filter: grayscale(0);
        }
      }
    }

    .rc-calendar-month-select,
    .rc-calendar-year-select {
      pointer-events: none;
    }
  }

  .matchy {
    .AH-calendar-customization {
      .rc-calendar-next-year-btn::after,
      .rc-calendar-prev-year-btn::after {
        background-image: url('./images/arrow-double-left-matchy.svg');
      }

      .rc-calendar-next-month-btn::after,
      .rc-calendar-prev-month-btn::after {
        background-image: url('./images/arrow-left-matchy.svg');
      }
    }
  }
}
