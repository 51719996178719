.photo {
  border-radius: 50%;
  object-fit: cover;

  &_size_s {
    width: 32px;
    height: 32px;
    font-size: 14px;
  }

  &_size_m {
    width: 100px;
    height: 100px;
    font-size: 50px;
  }

  &_size_l {
    width: 160px;
    height: 160px;
    font-size: 80px;
  }
}

.link {
  &:hover {
    text-decoration: none;
  }
}
