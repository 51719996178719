.input {
  align-items: center;
  border: 1px solid var(--grey-color-lighter);
  border-radius: 8px;
  box-shadow: 0 0 6px transparent;
  display: flex;
  min-height: 36px;
  padding: 3px 24px 3px 5px;
  transition: border-color .2s ease-in-out, box-shadow .2s ease-in-out;
  width: 100%;

  &-wrapper {
    display: flex;
    gap: 4px;
    position: relative;
    width: 100%;

    .icon {
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%) rotate(90deg);
      font-size: 16px;
      transition: transform .2s ease-in-out;

      &_open {
        transform: translateY(-50%) rotate(-90deg);
      }
    }
  }
}

.list {
  position: absolute;
  top: calc(100% + 8px);
  box-shadow: 0 3px 10px 0 rgba(31, 31, 31, 0.14);
  width: 312px;
  height: min-content;
  max-height: 180px;
  border-radius: 8px;
  background-color: var(--white-color);
  z-index: 1070;
  overflow: auto;
  padding: 12px 12px 12px 28px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
