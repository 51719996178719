$m: 16px;
$xl: 24px;

.text {
  font-size: $m;
  line-height: calc(#{$m} * 1.5);
  margin: 0 0;

  &_is {
    &_header {
      font-size: $xl;
      font-weight: bold;
    }
    &_strong {
      font-weight: bold;
      color: var(--grey-color-darkest);
    }
  }
}
