@import 'components/Base/Fonts/Fonts';

.container {
  position: relative;
  width: 100%;

  &_icon {
  }
}

.input {
  width: 100%;

  &_appearance_bordered {
    padding: 0 12px;
    border-radius: 8px;
    border: 1px solid var(--grey-color-light);
    outline: none;
    transition-duration: 0.3s;
    box-sizing: border-box;
    color: var(--black-color);

    &:focus {
      box-shadow: 0 0 6px var(--primary-background-color);
      border-color: var(--primary-color);
    }

    &.input_invalid {
      box-shadow: 0 0 2px var(--danger-color) !important;
      border-color: var(--danger-color) !important;
    }

    &:disabled {
      background: var(--grey-color-lightest);
      color: var(--grey-color);
      cursor: not-allowed;
    }
    &::placeholder {
      color: var(--grey-color-darker);
      font-weight: 300;
    }
  }

  &_appearance_lined {
    border: none;
    outline: none;
    border-bottom: 1px solid var(--grey-color-lighter);

    &:not([readonly]):hover {
      border-bottom-color: rgba(var(--primary-color--rgb), 0.5);
    }

    &:not([readonly]):focus {
      border-bottom-color: var(--primary-color);
    }

    &.input_invalid {
      border-bottom-color: var(--danger-color);

      &:hover {
        border-bottom-color: var(--danger-color);
      }
      &:focus {
        border-bottom-color: var(--danger-color);
      }
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  &_size_s {
    height: 20px;
    font-size: $s;
  }

  &_size_m {
    height: 40px;
    font-size: $l;
  }

  &_size_l {
    height: 40px;
    font-size: $l;
  }

  &_type_number {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  [type='number'] {
    -moz-appearance: textfield;
  }

  &_icon {
    padding-left: 32px;
  }
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
  color: var(--grey-color-lighter);
  font-size: 16px;
}
